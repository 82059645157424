import { Outlet } from "react-router-dom"
import Menu from "../Menu/Menu"
import menuConfig from '../../config/adminMenu.json'
import { useEffect, useState } from "react"
import UserDTO from "../../dto/UserDTO"
import userService from "../../services/UserService"

enum Status {
  loading,
  standard,
  noadmin,
}

const AdminLayout = () => {
  const [status, setStatus] = useState(Status.loading)
  const [user, setUser] = useState(new UserDTO())
  useEffect(() => {
    userService.get().then((u) => {
      if (!u) { return }
        setUser(u)
        setStatus(Status.standard)
    })
  }, [])
  // const user = userService.get()
  if (status === Status.loading) {
    return <div>Lade ...</div>
  }
  console.log('user.roles', user.roles)
  if (user.roles.indexOf('ROLE_ADMIN') === -1) {
    return <div className="w3-red">Du bist kein Admin</div>
  }
  return <div className='scrollY'>
    <Menu
      config={menuConfig}
    />
    <Outlet></Outlet>
  </div>
}
export default AdminLayout